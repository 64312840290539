import React, { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios"; // Import Axios
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Imageone from "../../assets/imagecarouselunique.png";
import Imagetwo from "../../assets/imagecaouseltwo.png";
import Imagethree from "../../assets/imagecarouselthree.png";
import Logo from "../../assets/logo.png";
import "swiper/css";
import "swiper/css/navigation";
import "../../pages/Carousel.css";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

function SubadminForgotmpin() {
  useEffect(() => {
    const disableRightClick = (e) => e.preventDefault();
    const disableShortcuts = (e) => {
      if (
        e.key === "F12" ||
        (e.ctrlKey && e.shiftKey && e.key === "I") ||
        (e.ctrlKey && e.shiftKey && e.key === "J") ||
        (e.ctrlKey && e.key === "U")
      ) {
        e.preventDefault();
      }
    };

    document.addEventListener("contextmenu", disableRightClick);
    document.addEventListener("keydown", disableShortcuts);

    return () => {
      document.removeEventListener("contextmenu", disableRightClick);
      document.removeEventListener("keydown", disableShortcuts);
    };
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/forgot-mpin`,
        {
          email: values.email,
        }
      );
      alert(response.data.message); // Alert the success message
      resetForm(); // Reset the form after successful submission
    } catch (error) {
      alert(
        error.response?.data?.message ||
          "An error occurred. Please try again later."
      ); // Alert the error message if available
    } finally {
      setSubmitting(false); // Stop the submission spinner
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={12}
          sm={4}
          md={7}
          sx={{
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Swiper
            spaceBetween={10}
            centeredSlides
            autoplay={{ delay: 2500, disableOnInteraction: false }}
            navigation={false}
            modules={[Autoplay, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imageone}
                alt="Image1 One"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imagetwo}
                alt="Image2 Two"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                style={{ width: "60%", height: "auto" }}
                src={Imagethree}
                alt="Image3 Three"
              />
            </SwiperSlide>
          </Swiper>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            backgroundColor: "#f0f2f5",
            alignItems: "center",
          }}
        >
          <img src={Logo} style={{ width: "11rem" }} alt="logo" />
          <Box
            style={{
              backgroundColor: "white",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              padding: "4rem",
              maxWidth: "400px",
              width: "100%",
            }}
            sx={{
              my: 4,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Recover Mpin
            </Typography>

            <Formik
              initialValues={{ email: "" }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Invalid email address")
                  .required("Email ID is required"),
              })}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form noValidate sx={{ mt: 1 }}>
                  <Field
                    as={TextField}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Enter your Email ID"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ padding: "10px" }}
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Recover Mpin"}
                  </Button>
                </Form>
              )}
            </Formik>

            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default SubadminForgotmpin;
