export const styles = {
    circle: {
        width: "70px", // Set the width of the circle
        height: "70px", // Set the height of the circle
        backgroundColor: "#007BB5", // Dark sky color
        borderRadius: "28%", // Make the div circular
        display: "flex", // Center content horizontally
        justifyContent: "center",
        alignItems: "center", // Center content vertically
        color: "white", // Set the text color inside the circle (if needed)
        fontSize: "18px", // Adjust the text size (if needed)
    },
}
