import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PersonIcon from "@mui/icons-material/Person";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Userpage from "../Menutabs/Userpage";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import Userdashboardmenu from "../Menutabs/Userdashboardmenu";
import Logo from "../assets/logo.png";
import Commonbutton from "../commoncomponents/Commonbutton";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ChatIcon from "@mui/icons-material/Chat";
import InputIcon from "@mui/icons-material/Input";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Addmoney from "../Menutabs/Addmoney";
import { useNavigate } from "react-router-dom";
import Wallet from "../pages/Wallet";
// import HomeIcon from '@mui/icons-material/Home';
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import Accordion from "@mui/material/Accordion";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import Upipayout from "../pages/Upipayout";
import Payoutreport from "../pages/Payoutreport";
import "./Menu.css";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../redux/Balance";
import clsx from "clsx";
import Changemobile from "../Menutabs/Changemobile";
import Changepassword from "../Menutabs/Changepassword";
import Changempin from "../Menutabs/Changempin";
import Aadharkyc from "../Menutabs/Aadharkyc";
import Aepswallet from "./Aepswallet";
import Aepswalletsummary from "../Menutabs/Aepswalletsummary";
import Addfundrequest from "../Menutabs/Addfundrequest";
import Listfundrequest from "../Menutabs/Listfundrequest";
import Profile from "../Menutabs/Profile";
import Recharge from "../Menutabs/Recharge";
import Newpayout from "../Menutabs/Newpayout";
import "./Dashboardmenubar.css";
import Virtualaccountreport from "../Menutabs/Virtualaccountreport";
import Newpayoutreport from "../Menutabs/Newpayoutreport";

import Api from "../apiconfig/Api";

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Dashboardmenubar() {
  const [expanded, setExpanded] = useState(false);
  const [selectedtab, setSelectedtab] = useState(0);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownprofile, setDropdownprofile] = useState(false);
  const [dropdownservices, setDropdownservices] = useState(false);
  const [dropdownreport, setDropdownreport] = useState(false);
  const [dropdownimportantlink, setDropdownimportantlink] = useState(false);
  const [dropdownxpressdmr, setDropdownxpressdmr] = useState(false);
  const [dropdownmoneytransfer, setDropdownmoneytransfer] = useState(false);
  const [dropdownwallet, setDropdownwallet] = useState(false);
  const [balance, setBalance] = useState([]);
  const openicon = Boolean(anchorEl);

  const { users } = useSelector((state) => state.Balance);

const url = Api.GET_BALANCE_URL;
  const Balancefetch = async() => {

    const jwtToken = localStorage.getItem("authToken");

    try{
      const response = await fetch(url,{
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`, // Correct format for Authorization header
        },
      });
      const data = await response.json();
      setBalance(data);
      console.log(data, "databalance");
    }catch(error){
    console.log(error, "error");
    }
  };

  useEffect(()=>{
    Balancefetch();
  }, [])



  console.log(balance.balance, "responsebalance")


  const token = localStorage.getItem("authToken");
  localStorage.setItem("Balance", balance.balance);
  
  //https://zevopay.online/api/v1/user

  useEffect(() => {
    // Fetch user data
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          console.log(data, "dataresponsejj");
          // localStorage.setItem("Accountnumberdetail", virtual_account);
          
          setUserData(data);
        } else {
          console.error("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, [token]);

  // console.log(userData, "usercredential");
  // localStorage.setItem("Accountnumberdetail", userData.virtual_account);

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // menu user icon
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  //logout button

  const handleClose = () => {
    // navigate("/");
    setAnchorEl(null);
  };

  // menu button

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  // logout button
  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Clear the token from local storage
    navigate("/"); // Redirect to login page
    setAnchorEl(null); // Close the menu
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  const ExpandIcon = styled(ExpandMoreIcon)(({ theme, expanded }) => ({
    transform: expanded ? "rotate(0deg)" : "rotate(-90deg)",
    transition: "transform 0.3s",
  }));

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const toggleProfiledown = () => {
    setDropdownprofile((prev) => !prev);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  const toggleServicesdown = () => {
    setDropdownservices((prev) => !prev);
  };

  const toggleReportdown = () => {
    setDropdownreport((prev) => !prev);
  };

  const toggleImportantlinkdown = () => {
    setDropdownimportantlink((prev) => !prev);
  };

  const toggleXpressdmrdown = () => {
    setDropdownxpressdmr((prev) => !prev);
  };

  const toggleMoneytransferdown = () => {
    setDropdownmoneytransfer((prev) => !prev);
  };

  const toggleWalletdown = () => {
    setDropdownwallet((prev) => !prev);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      <AppBar position="fixed" open={open} style={{ backgroundColor: "#fff" }}>
        <Toolbar
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              // marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon style={{ color: "black" }} />
          </IconButton>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "9px",
                textAlign: "center",
                marginLeft: "auto",
              }}
            >
              <Commonbutton
                onClick={() => {
                  setSelectedtab(2);
                }}
              />

              <button
                style={{
                  backgroundColor: "#F7CAC9",
                  padding: "10px",
                  borderColor: "transparent",
                  color: "lightslategrey",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                {`${localStorage.getItem("UserId")}`}
              </button>

              <button
                style={{
                  backgroundColor: "#F7CAC9",
                  padding: "10px",
                  borderColor: "transparent",
                  color: "lightslategrey",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                AEPS Balance
              </button>
              <button
                style={{
                  backgroundColor: "#F7CAC9",
                  padding: "10px",
                  borderColor: "transparent",
                  color: "lightslategrey",
                  fontFamily: "sans-serif",
                  fontWeight: "bold",
                }}
              >
                EBalance <br></br> {balance.balance} {balance.currency}
              </button>

              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar sx={{ width: 32, height: 32 }}>M</Avatar>
                </IconButton>
              </Tooltip>
            </Box>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openicon}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
            >
              <MenuItem onClick={handleClose}>
                <Avatar /> Profile
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Avatar /> My account
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                Add another account
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{ backgroundColor: "#F8F8FB" }}>
          <div
            style={{
              display: "flex",
              // backgroundColor:"red",
              justifyContent: "center",
              alignItems: "center",
              height: "70px",
            }}
          >
            <img src={Logo} style={{ width: "70%" }} alt="company logo" />
          </div>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        {open && (
          <>
            <List style={{ backgroundColor: "#3f0097" }}>
              <ListItem>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Avatar
                  src={userData.imageUrl ? userData.imageUrl.replace("http://localhost:3040", "") : ""} 
                    alt="Cindy Baker"
                    style={{ width: "80px", height: "80px" }}
                  />
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#AAADB7",
                        fontWeight: "bold",
                        fontSize: "20px",
                      }}
                    >
                      {localStorage.getItem("Name")}
                    </span>
                    <span
                      style={{
                        color: "#AAADB7",
                        fontSize: "13px",
                        fontSize: "15px",
                      }}
                    >
                      Retailer
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-around",
                      gap: "20px",
                      marginTop: "10px",
                    }}
                  >
                    <PersonOutlineIcon
                      sx={{ fontSize: 30, color: "#AAADB7" }}
                    />
                    <MailOutlineIcon sx={{ fontSize: 30, color: "#AAADB7" }} />
                    <ChatIcon sx={{ fontSize: 30, color: "#AAADB7" }} />
                    <InputIcon
                      onClick={handleLogout}
                      sx={{ fontSize: 30, color: "#AAADB7" }}
                    />
                  </div>
                </div>
              </ListItem>
            </List>
            <Divider />
          </>
        )}

        <List style={{ backgroundColor: "#3f0097", height: "100vh" }}>
          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              onClick={() => setSelectedtab(0)}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon sx={{ fontSize: 30, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Dashboard"}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block", "&:hover": { backgroundColor: "yellow" } }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownprofile}
              onClick={() => toggleProfiledown()}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&::after": {
                  color: "white", // This will change the dropdown arrow (toggle) color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PersonIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"My Profile"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownprofile && (
              <ul
                className="dropdown-menu show"
                style={{
                  display: "block",
                  width: "100%",
                  backgroundColor: "#3f0097",
                  paddingLeft: "15px",
                }}
              >
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(14); // Set the tab first
                      toggleProfiledown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    sx={{
                      "&:hover": {
                        color: "red", // Change text color to red on hover
                      },
                    }}
                    href="#"
                  >
                    Profile
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      toggleProfiledown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    sx={{
                      "&:hover": {
                        color: "red",
                      },
                    }}
                    href="#"
                  >
                    Download Certificate
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      toggleProfiledown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    sx={{
                      "&:hover": {
                        color: "red",
                      },
                    }}
                    href="#"
                  >
                    Download IDCard
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(9); // Set the tab first
                      toggleProfiledown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    sx={{
                      "&:hover": {
                        color: "red",
                      },
                    }}
                    href="#"
                  >
                    Aadhar KYC
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(6); // Set the tab first
                      toggleProfiledown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    sx={{
                      "&:hover": {
                        color: "red",
                      },
                    }}
                    href="#"
                  >
                    Change Mobile
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(7); // Set the tab first
                      toggleProfiledown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    sx={{
                      "&:hover": {
                        color: "red",
                      },
                    }}
                    href="#"
                  >
                    Change Password
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(8); // Set the tab first
                      toggleProfiledown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    sx={{
                      "&:hover": {
                        color: "red",
                      },
                    }}
                    href="#"
                  >
                    Change Pin
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownwallet}
              onClick={() => {
                // setSelectedtab(1);
                toggleWalletdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&::after": {
                  color: "white", // This will change the dropdown arrow (toggle) color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <BusinessCenterIcon
                  onClick={() => setSelectedtab(3)}
                  sx={{ fontSize: 25, color: "#AAADB7" }}
                />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"wallet"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownwallet && (
              <ul
                className="dropdown-menu show"
                style={{
                  display: "block",
                  width: "100%",
                  backgroundColor: "#3f0097",
                  paddingLeft: "15px",
                  // gap:10
                }}
              >
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(3); // Set the tab first
                      toggleWalletdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Ewallet
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(10); // Set the tab first
                      toggleWalletdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    AepsWallet
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(11); // Set the tab first
                      toggleWalletdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Aeps Wallet Summary
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(12); // Set the tab first
                      toggleWalletdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Add Fund Request
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(13); // Set the tab first
                      toggleWalletdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    List fund Request
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownservices}
              onClick={() => {
                // setSelectedtab(1);
                toggleServicesdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&::after": {
                  color: "white", // This will change the dropdown arrow (toggle) color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <VpnKeyIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Services"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownservices && (
              <ul
                className="dropdown-menu show"
                style={{
                  paddingLeft: "15px",
                  display: "block",
                  width: "100%",
                  backgroundColor: "#3f0097",
                }}
              >
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(15); // Set the tab first
                      toggleServicesdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Recharge
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleServicesdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(5)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    BBPS
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleServicesdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(5)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    UTI Registration
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleServicesdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(5)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    UTI Coupon
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleServicesdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(5)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    LIC
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleServicesdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(5)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Product
                  </span>
                </li>

                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleServicesdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(5)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Offline Services
                  </span>
                </li>

                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleServicesdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(5)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    IRCTC
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownOpen}
              onClick={() => {
                // setSelectedtab(1);
                toggleDropdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&::after": {
                  color: "white", // This will change the dropdown arrow (toggle) color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PeopleAltIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Financial Services"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownOpen && (
              <ul
                className="dropdown-menu show"
                style={{
                  paddingLeft: "15px",
                  display: "block",
                  width: "100%",
                  backgroundColor: "#3f0097",
                }}
              >
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleDropdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    DMR
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleDropdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    AEPS
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleDropdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Aeps Payout
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleDropdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Xpress Payout
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(16); // Set the tab first
                      toggleDropdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    New Payout
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(4); // Set the tab first
                      toggleDropdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    UPI Payout
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(5); // Set the tab first
                      toggleDropdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    UP/New Payout Report
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownreport}
              onClick={() => {
                // setSelectedtab(1);
                toggleReportdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&::after": {
                  color: "white", // This will change the dropdown arrow (toggle) color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DirectionsCarIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Reports"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownreport && (
              <ul
                className="dropdown-menu show"
                style={{
                  paddingLeft: "15px",
                  display: "block",
                  width: "100%",
                  backgroundColor: "#3f0097",
                }}
              >
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Recharge Report
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    BBPS Report
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    DMR Report
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Aeps Report
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    UTI Coupan
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    offline Services
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(15); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Payout Report
                  </span>
                </li>

                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(18); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    New Upi Report
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      setSelectedtab(17); // Set the tab first
                      toggleReportdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Virtual account Report
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownimportantlink}
              onClick={() => {
                // setSelectedtab(1);
                toggleImportantlinkdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&::after": {
                  color: "white", // This will change the dropdown arrow (toggle) color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DirectionsCarIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Important Link"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownimportantlink && (
              <ul
                className="dropdown-menu show"
                style={{
                  paddingLeft: "15px",
                  display: "block",
                  width: "100%",
                  backgroundColor: "#3f0097",
                }}
              >
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(14); // Set the tab first
                      toggleImportantlinkdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Important Link
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownxpressdmr}
              onClick={() => {
                // setSelectedtab(1);
                toggleXpressdmrdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&::after": {
                  color: "white", // This will change the dropdown arrow (toggle) color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DirectionsCarIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Xpress Dmr"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownxpressdmr && (
              <ul
                className="dropdown-menu show"
                style={{
                  paddingLeft: "15px",
                  display: "block",
                  width: "100%",
                  backgroundColor: "#3f0097",
                }}
              >
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(14); // Set the tab first
                      toggleXpressdmrdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Xpress Dmr
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(14); // Set the tab first
                      toggleXpressdmrdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Xpress Dmr Report
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton
              className="btn btn-secondary dropdown-toggle"
              href="#"
              role="button"
              aria-expanded={dropdownmoneytransfer}
              onClick={() => {
                // setSelectedtab(1);
                toggleMoneytransferdown();
              }}
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
                "&::after": {
                  color: "white", // This will change the dropdown arrow (toggle) color
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DirectionsCarIcon sx={{ fontSize: 25, color: "#AAADB7" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "#B4B6B8" }}
                primary={"Money Transfer"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
            {open && dropdownmoneytransfer && (
              <ul
                className="dropdown-menu show"
                style={{
                  paddingLeft: "15px",
                  display: "block",
                  width: "100%",
                  backgroundColor: "#3f0097",
                }}
              >
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(14); // Set the tab first
                      toggleMoneytransferdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    UPI Payout
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(14); // Set the tab first
                      toggleMoneytransferdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Money Transfer
                  </span>
                </li>
                <li style={{ cursor: "pointer" }}>
                  <span
                    onClick={() => {
                      // setSelectedtab(14); // Set the tab first
                      toggleMoneytransferdown(); // Then close the dropdown
                    }}
                    // onClick={() => setSelectedtab(4)}
                    style={{ fontSize: "18px", color: "darkgray" }}
                    className="drop"
                    href="#"
                  >
                    Report
                  </span>
                </li>
              </ul>
            )}
          </ListItem>

          <ListItem
            disablePadding
            sx={{
              display: "block",
              "&:hover": {
                backgroundColor: "yellow",
              },
            }}
            style={{ borderRadius: "10px" }}
          >
            <ListItemButton>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon sx={{ fontSize: 25, color: "black" }} />
              </ListItemIcon>
              <ListItemText
                style={{ fontSize: "20px", color: "black" }}
                primary={"Download Invoice"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {selectedtab === 0 && <Userdashboardmenu />}
        {selectedtab === 1 && <Userpage />}
        {selectedtab === 2 && <Addmoney />}
        {selectedtab === 3 && <Wallet />}
        {selectedtab === 4 && <Upipayout />}
        {selectedtab === 5 && <Payoutreport />}
        {selectedtab === 6 && <Changemobile />}
        {selectedtab === 7 && <Changepassword />}
        {selectedtab === 8 && <Changempin />}
        {selectedtab === 9 && <Aadharkyc />}
        {selectedtab === 10 && <Aepswallet />}
        {selectedtab === 11 && <Aepswalletsummary />}
        {selectedtab === 12 && <Addfundrequest />}
        {selectedtab === 13 && <Listfundrequest />}
        {selectedtab === 14 && <Profile />}
        {selectedtab === 15 && <Recharge />}
        {selectedtab === 16 && <Newpayout />}
        {selectedtab === 17 && <Virtualaccountreport />}
        {selectedtab === 18 && <Newpayoutreport />}
        {/* Add more tab content components as needed */}
      </Box>
    </Box>
  );
}

export default Dashboardmenubar;
