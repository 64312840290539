import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import "./Subadminlogin.css";
import { useNavigate } from "react-router";
import axios from "axios"; // Ensure axios is imported
import useCommonBackgrouncolor from "../commoncomponents/colorhook/Backgroundcolor";
import Commonfield from "../commoncomponents/common/Commonfield";
import Commonbutton from "../commoncomponents/common/Commonbutton";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Subadminlogin({ setSubadminLoggedin }) {
  useCommonBackgrouncolor();
  const navigate = useNavigate();

  const [state, setState] = useState({
    isLoggedIn: false,
    mpin: "",
    showPassword: false,
  });

  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Password is a required field")
      .min(8, "Password must be at least 8 characters"),
  });

  const handleLogin = async (values) => {
    const url = `${process.env.REACT_APP_API_URL}/auth/subadmin/login`;
  
    try {
      const response = await axios.post(url, {
        email: values.email,
        password: values.password,
      });
  
      if (response.status === 200) {
        const { token } = response.data;
  
        // Store the sub-admin token in localStorage
        localStorage.setItem("subadmin", token);
  
        // Update login status
        setSubadminLoggedin(true);
  
        setState((prevState) => ({
          ...prevState,
          isLoggedIn: true,
        }));
  
        alert("Login successful! Please enter your MPIN to proceed.");
      } else {
        alert("Login failed. Please try again.");
      }
    } catch (error) {
      alert(error.response?.data?.message || "An error occurred during login.");
    }
  };
  

  const handleVerifyMPIN = () => {
    const payload = { mpin: state.mpin };
    const token = localStorage.getItem("subadmin");
  
    axios
      .post(`${process.env.REACT_APP_API_URL}/auth/subadmin/verify-mpin`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const { role, permissions } = response.data;
  
        if (role === "SUB_ADMIN") {
          // Navigate to the dashboard
          navigate("/Subadmin-dashboard", { state: { permissions } });
        } else {
          alert("Invalid role.");
        }
      })
      .catch((error) => {
        alert("Invalid MPIN. Please try again.");
      });
  };
  

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {state.isLoggedIn ? (
          <div
            className="mpin-container"
            style={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <label style={{ fontSize: 14, fontWeight: "700", color: "#fff" }}>
              MPIN *
            </label>
            <div style={{ position: "relative", margin: "10px 0" }}>
              <input
                type={state.showPassword ? "text" : "password"}
                value={state.mpin}
                placeholder="enter your mpin"
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    mpin: e.target.value,
                  }))
                }
                style={{
                  padding: "5px 40px 5px 10px",
                  fontSize: "16px",
                  borderRadius: "10px",
                  width: "230px",
                }}
              />
              <button
                type="button"
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showPassword: !state.showPassword,
                  }))
                }
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "16px",
                }}
              >
                {state.showPassword ? <Visibility /> : <VisibilityOff />}
              </button>
            </div>
            <Commonbutton
              styles={{
                backgroundColor: "#000080",
                cursor: "pointer",
                padding: "7px 40px",
                fontSize: "16px",
                color: "white",
                border: "none",
                borderRadius: "4px",
              }}
              onClick={() => {
                handleVerifyMPIN()
              }}
              text={"Verify"}
            />
          </div>
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{ email: "", password: "" }}
            onSubmit={handleLogin}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <div className="login">
                <div className="form">
                  <form noValidate onSubmit={handleSubmit}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label style={{ fontSize: 14, fontWeight: "700" }}>
                        Email
                      </label>
                      <Commonfield
                        type={"email"}
                        name={"email"}
                        onchange={handleChange}
                        placeholder={"Enter email id"}
                        onblur={handleBlur}
                        values={values.email}
                        id={"email"}
                      />
                      <p className="error">
                        {errors.email && touched.email && errors.email}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <label style={{ fontSize: 14, fontWeight: "700" }}>
                        Password
                      </label>
                      <Commonfield
                        type={"password"}
                        name={"password"}
                        onchange={handleChange}
                        onblur={handleBlur}
                        values={values.password}
                        placeholder={"Enter password"}
                      />
                      <p className="error" style={{ marginBottom: "4px" }}>
                        {errors.password &&
                          touched.password &&
                          errors.password}
                      </p>

                      {/* Compact Forgot Password Text */}
                      <label
                        style={{
                          fontSize: "12px",
                          color: "#007bff", // Blue color for link-like appearance
                          cursor: "pointer",
                          textDecoration: "underline",
                          // marginTop: "2px", // Minimal space above the text
                        }}
                        onClick={() => {
                          navigate('/Forgotpassword')
                        }}
                      >
                        Forgot Password?
                      </label>

                      <label
                        style={{
                          fontSize: "12px",
                          color: "#007bff", // Blue color for link-like appearance
                          cursor: "pointer",
                          textDecoration: "underline",
                          marginTop: "2px", // Minimal space above the text
                        }}
                        onClick={() => {
                          navigate('/Forgotmpin')
                        }}
                      >
                        Forgot Mpin?
                      </label>
                    </div>

                    <Commonbutton text={"Sign in"} />
                  </form>
                </div>
              </div>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default Subadminlogin;
