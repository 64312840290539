import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

const Subadmincreditfund = () => {
  const [memberList, setMemberList] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [amount, setAmount] = useState("");
  const [narration, setNarration] = useState("");
  const [selectedMemberBalance, setSelectedMemberBalance] = useState(null);
  const token = localStorage.getItem("subadmin");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/subadmin/user/getMemberIds`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data, "data");
        setMemberList(data.data);
      })
      .catch((error) => {
        console.error("Error fetching member IDs:", error);
      });
  }, [token]);

  const handleMemberChange = (memberId) => {
    setSelectedMember(memberId);

    const selectedMemberData = memberList.find(
      (member) => member.id === Number(memberId)
    );
    if (selectedMemberData) {
      setSelectedMemberBalance(selectedMemberData.balance);
    } else {
      setSelectedMemberBalance(null);
    }
  };

  const handleSubmit = () => {
    if (!selectedMember || !amount || !narration) {
      alert("Please fill all fields.");
      return;
    }

    const payload = {
      userId: Number(selectedMember),
      amount: Number(amount),
      narration: narration,
    };

    fetch(`${process.env.REACT_APP_API_URL}/subadmin/wallet/add-money`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((errorData) => {
            throw new Error(errorData.message || "Something went wrong");
          });
        }
        return response.json();
      })
      .then((data) => {
        alert("Money credited successfully!");

        fetch(`${process.env.REACT_APP_API_URL}/subadmin/user/getMemberIds`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => response.json())
          .then((updatedData) => {
            setMemberList(updatedData.data);

            const updatedMember = updatedData.data.find(
              (member) => member.id === Number(selectedMember)
            );
            if (updatedMember) {
              setSelectedMemberBalance(updatedMember.balance);
            }
          })
          .catch((error) => {
            console.error("Error fetching updated balance:", error);
          });

        setAmount("");
        setNarration("");
      })
      .catch((error) => {
        console.error("Error adding money:", error);
        alert("Money not credited due to an error.");
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h4>Credit Fund in E Wallet</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>MemberID *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="input-group mb-3">
              <select
                className="form-select"
                id="inputGroupSelect02"
                value={selectedMember}
                onChange={(e) => handleMemberChange(e.target.value)}
              >
                <option value="" disabled>
                  Choose...
                </option>
                {memberList.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.memberId}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Amount *</span>
          </Grid>

          <Grid item xs={12} md={6}>
            <div style={{ textAlign: "right" }}>
              <p
                style={{
                  margin: "0",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Total Balance: ₹{selectedMemberBalance ?? ""}
              </p>
              <input
                style={{
                  width: "100%",
                  height: "47px",
                  borderRadius: "10px",
                  borderColor: "lightgray",
                }}
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Narration *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "120px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
            />
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "70px",
            gap: 30,
          }}
        >
          <button
            style={{
              backgroundColor: "#F08080",
              padding: "5px",
              borderRadius: "15px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              width: "70px",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            style={{
              backgroundColor: "#F08080",
              padding: "5px",
              borderRadius: "15px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              width: "70px",
            }}
            onClick={() => {
              setSelectedMember("");
              setAmount("");
              setNarration("");
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default Subadmincreditfund;
