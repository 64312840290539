export const Checklisthook = [

    {
        id: 'Home',
        name: 'Home',
        children: [
            { id: 'Homepanel', name: 'Homepanel' },
        ],
    },
    {
        id: 'Members',
        name: 'Members',
        children: [
            { id: 'Member List', name: 'Member List' },
            { id: 'New registration', name: 'New registration' },
            { id: 'User Qrupload', name: 'User Qrupload' },
            { id: 'User Profileupload', name: 'User Profileupload' },
        ],
    },

    {
        id: 'Wallet',
        name: 'Wallet',
        children: [
            { id: 'Ewallet', name: 'Ewallet' },
            { id: 'Credit Fund', name: 'Credit Fund' },
            { id: 'Debit Fund', name: 'Debit Fund' },
        ],
    },

    {
        id: 'Report',
        name: 'Report',
        children: [
            { id: 'Member Report', name: 'Member Report' },
            { id: 'New Upi report', name: 'New Upi report' },
            { id: 'Virtual Account Report', name: 'Virtual Account Report' },
        ],
    },

    {
        id: 'Setting',
        name: 'Setting',
        children: [
            { id: 'Surplus Report', name: 'Surplus Report' },
            { id: 'Surplus Collection', name: 'Surplus Collection' },
        ],
    },

]