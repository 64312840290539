import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";

const Debitfund = () => {
  const [memberList, setMemberList] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [amount, setAmount] = useState("");
  const [authotp, setAuthotp] = useState("");
  const [narration, setNarration] = useState("");
  const [selectedMemberBalance, setSelectedMemberBalance] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const token = localStorage.getItem("adminAuthToken");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/admin/user/getMemberIds`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data.data, "data");
        setMemberList(data.data);
      })
      .catch((error) => {
        console.error("Error fetching member IDs:", error);
      });
  }, [token]);

  // show totoal  balance in ui   ui  function
  const handleMemberChange = (memberId) => {
    setSelectedMember(memberId);

    // Find the selected member's balance
    const selectedMemberData = memberList.find(
      (member) => member.id === Number(memberId)
    );
    if (selectedMemberData) {
      setSelectedMemberBalance(selectedMemberData.balance);
    } else {
      setSelectedMemberBalance(null);
    }
  };

  const handleSubmit = () => {
    if (!selectedMember || !amount || !narration || !authotp) {
      alert("Please fill all fields.");
      return;
    }

    if (amount > selectedMemberBalance) {
      alert("Wallet don't enough balance to withdrawal");
    } else {
      fetch(`${process.env.REACT_APP_API_URL}/auth/2fa/authenticate`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ twoFactorAuthenticationCode: authotp }),
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              throw new Error(errorData.message || "Invalid OTP");
            });
          }
          return response.json();
        })
        .then((data) => {
          if (data.access_token) {
            // Proceed to add money only if OTP is valid
            const payload = {
              userId: Number(selectedMember),
              amount: Number(amount),
              narration: narration,
            };

            fetch(`${process.env.REACT_APP_API_URL}/admin/wallet/debit-money`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            })
              .then((response) => {
                if (!response.ok) {
                  return response.json().then((errorData) => {
                    throw new Error(
                      errorData.message || "Something went wrong"
                    );
                  });
                }
                return response.json();
              })
              .then((data) => {
                if (data.status === "error" && data.message.includes("UTR")) {
                  alert("Money not debited due to a copied UTR number.");
                } else {
                  alert("Money debited successfully!");

                  // Fetch updated balance for the selected member
                  fetch(
                    `${process.env.REACT_APP_API_URL}/admin/user/getMemberIds`,
                    {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                    .then((response) => response.json())
                    .then((updatedData) => {
                      setMemberList(updatedData.data);

                      // Update the selected member balance
                      const updatedMember = updatedData.data.find(
                        (member) => member.id === Number(selectedMember)
                      );
                      if (updatedMember) {
                        setSelectedMemberBalance(updatedMember.balance);
                      }
                    })
                    .catch((error) => {
                      console.error("Error fetching updated balance:", error);
                    });

                  // Reset the input fields
                  setAmount("");
                  setNarration("");
                  setAuthotp("");
                  setErrorMessage("");
                }
              })
              .catch((error) => {
                console.error("Error adding money:", error);
                alert("Money not credited due to an error.");
              });
          }
        })
        .catch((error) => {
          console.error("Error verifying OTP:", error);
          setErrorMessage("Wrong OTP. Please try again.");
        });
    }

    // Verify OTP
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h4>Debit Fund in E Wallet</h4>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
        }}
      >
        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>MemberID *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <div className="input-group mb-3">
              <select
                className="form-select"
                id="inputGroupSelect02"
                value={selectedMember}
                onChange={(e) => handleMemberChange(e.target.value)}
              >
                <option value="" disabled>
                  Choose...
                </option>
                {memberList.map((member) => (
                  <option key={member.id} value={member.id}>
                    {member.memberId}
                  </option>
                ))}
              </select>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Amount *</span>
          </Grid>

          <Grid item xs={12} md={6}>
            <div style={{ textAlign: "right" }}>
              <p
                style={{
                  margin: "0",
                  color: "black",
                  fontSize: "18px",
                  fontWeight: "bold",
                }}
              >
                Total Balance: ₹{selectedMemberBalance ?? ""}
              </p>
              <input
                style={{
                  width: "100%",
                  height: "47px",
                  borderRadius: "10px",
                  borderColor: "lightgray",
                }}
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Narration *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "120px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
              value={narration}
              onChange={(e) => setNarration(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Auth OTP *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "47px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
              type="number"
              value={authotp}
              onChange={(e) => setAuthotp(e.target.value)}
            />
          </Grid>
        </Grid>

        {errorMessage && (
          <div style={{ color: "red", textAlign: "center", marginTop: "10px" }}>
            {errorMessage}
          </div>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginLeft: "70px",
            gap: 30,
          }}
        >
          <button
            style={{
              backgroundColor: "#F08080",
              padding: "5px",
              borderRadius: "15px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              width: "70px",
            }}
            onClick={handleSubmit}
          >
            Submit
          </button>
          <button
            style={{
              backgroundColor: "#F08080",
              padding: "5px",
              borderRadius: "15px",
              color: "#fff",
              fontSize: "14px",
              fontWeight: "bold",
              borderColor: "transparent",
              width: "70px",
            }}
            onClick={() => {
              setSelectedMember("");
              setAmount("");
              setNarration("");
              setAuthotp("");
              setErrorMessage("");
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default Debitfund;

// import React, { useState, useEffect } from "react";
// import { Grid } from "@mui/material";

// const Debitfund = () => {
//   const [memberList, setMemberList] = useState([]);
//   const [selectedMember, setSelectedMember] = useState("");
//   const [amount, setAmount] = useState("");
//   const [authotp, setAuthotp] = useState("");
//   const [narration, setNarration] = useState("");
//   const token = localStorage.getItem("adminAuthToken");

//   // Fetch member IDs on component mount
//   useEffect(() => {
//     fetch(`${process.env.REACT_APP_API_URL}/admin/user/getMemberIds`, {
//       method: "GET",
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setMemberList(data.data);
//       })
//       .catch((error) => {
//         console.error("Error fetching member IDs:", error);
//       });
//   }, [token]);

//   const adminToken = localStorage.getItem("adminAuthTokenstored");

//   const handleSubmit = async () => {
//     if (!selectedMember || !amount || !narration || !authotp) {
//       alert("Please fill all fields.");
//       return;
//     }

//     try {
//       // Step 1: Two-Factor Authentication API Call
//       const twoFactorResponse = await fetch(
//         `${process.env.REACT_APP_API_URL}/auth/2fa/authenticate`,
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${adminToken}`,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify({ twoFactorAuthenticationCode: authotp }),
//         }
//       );

//       if (!twoFactorResponse.ok) {
//         const errorData = await twoFactorResponse.json();
//         throw new Error(errorData.message || "Two-factor authentication failed.");
//       }

//       const twoFactorData = await twoFactorResponse.json();
//       const { access_token } = twoFactorData;

//       if (!access_token) {
//         alert("Two-factor authentication failed. Please try again.");
//         return;
//       }

//       // Step 2: Proceed to Add Money API Call
//       const payload = {
//         userId: Number(selectedMember),
//         amount: Number(amount),
//         narration: narration,
//       };

//       const addMoneyResponse = await fetch(
//         `${process.env.REACT_APP_API_URL}/admin/wallet/debit-money`,
//         {
//           method: "POST",
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//           },
//           body: JSON.stringify(payload),
//         }
//       );

//       if (!addMoneyResponse.ok) {
//         const errorData = await addMoneyResponse.json();
//         throw new Error(errorData.message || "Something went wrong");
//       }

//       const addMoneyData = await addMoneyResponse.json();

//       if (addMoneyData.status === "error" && addMoneyData.message.includes("UTR")) {
//         alert("Money not credited due to a copied UTR number.");
//       } else {
//         alert("Money debited successfully!");
//         // Reset the form after successful submission
//         setSelectedMember("");
//         setAmount("");
//         setNarration("");
//         setAuthotp("");
//       }
//     } catch (error) {
//       console.error("Error adding money:", error);
//       alert(error.message || "An error occurred while processing your request.");
//     }
//   };

//   return (
//     <div style={{ display: "flex", flexDirection: "column" }}>
//     <h4>Debit Fund in E Wallet</h4>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           gap: 20,
//           backgroundColor: "#fff",
//           boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
//           padding: "20px",
//           paddingLeft: "70px",
//           paddingRight: "70px",
//         }}
//       >
//         <Grid
//           container
//           spacing={1}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item xs={12} md={6}>
//             <span>MemberID *</span>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <div className="input-group mb-3">
//               <select
//                 className="form-select"
//                 id="inputGroupSelect02"
//                 value={selectedMember}
//                 onChange={(e) => setSelectedMember(e.target.value)}
//               >
//                 <option value="" disabled>
//                   Choose...
//                 </option>
//                 {memberList.map((member) => (
//                   <option key={member.id} value={member.id}>
//                     {member.memberId}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </Grid>
//         </Grid>

//         <Grid
//           container
//           spacing={1}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item xs={12} md={6}>
//             <span>Amount *</span>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <input
//               style={{
//                 width: "100%",
//                 height: "47px",
//                 borderRadius: "10px",
//                 borderColor: "lightgray",
//               }}
//               type="number"
//               value={amount}
//               onChange={(e) => setAmount(e.target.value)}
//             />
//           </Grid>
//         </Grid>

//         <Grid
//           container
//           spacing={1}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item xs={12} md={6}>
//             <span>Narration *</span>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <input
//               style={{
//                 width: "100%",
//                 height: "120px",
//                 borderRadius: "10px",
//                 borderColor: "lightgray",
//               }}
//               value={narration}
//               onChange={(e) => setNarration(e.target.value)}
//             />
//           </Grid>
//         </Grid>

//         <Grid
//           container
//           spacing={1}
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//           }}
//         >
//           <Grid item xs={12} md={6}>
//             <span>Auth otp *</span>
//           </Grid>
//           <Grid item xs={12} md={6}>
//             <input
//               style={{
//                 width: "100%",
//                 height: "47px",
//                 borderRadius: "10px",
//                 borderColor: "lightgray",
//               }}
//               type="number"
//               value={authotp}
//               onChange={(e) => setAuthotp(e.target.value)}
//             />
//           </Grid>
//         </Grid>

//         <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             marginLeft: "70px",
//             gap: 30,
//           }}
//         >
//           <button
//             style={{
//               backgroundColor: "#F08080",
//               padding: "5px",
//               borderRadius: "15px",
//               color: "#fff",
//               fontSize: "14px",
//               fontWeight: "bold",
//               borderColor: "transparent",
//               width: "70px",
//             }}
//             onClick={handleSubmit}
//           >
//             Submit
//           </button>
//           <button
//             style={{
//               backgroundColor: "#F08080",
//               padding: "5px",
//               borderRadius: "15px",
//               color: "#fff",
//               fontSize: "14px",
//               fontWeight: "bold",
//               borderColor: "transparent",
//               width: "70px",
//             }}
//             onClick={() => {
//               setSelectedMember("");
//               setAmount("");
//               setNarration("");
//               setAuthotp("");
//             }}
//           >
//             Reset
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Debitfund;
