import React, { useEffect, useState } from "react";
import "./Addmoney.css";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Useraccountdetail from "../components/Useraccountdetail";
import { useSelector, useDispatch } from "react-redux";
import { getAllData } from "../redux/Userdetail";
import bottom from "../assets/bottom.png";

const Addmoney = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState(null);
  const token = localStorage.getItem("authToken");

  //new lind added
  const { users, loading, error } = useSelector((state) => state.Userdata);

  console.log(users, "datashyam");

  // const virtualaccountno = users.virtual_account.toLowerCase();

  const Name = localStorage.getItem("Name");

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

 //new line
  const virtualAccount = users?.virtual_account
    ? users.virtual_account.toLowerCase()
    : "";


    

  return (
    <Container>
      <Box my={4}>
        <Grid container spacing={2}>
          {/* Left Section (QR Code) */}
          <Grid item xs={12} sm={6} className="child-div">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h2 style={{ fontWeight: "500" }}>{Name}</h2>
              <div style={{ width: "100%", height: "auto", maxWidth: "300px" }}>
                {/* Display user's QR code if available, else show a default QR code */}
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={users.qrUrl ? users.qrUrl.replace("http://localhost:3040", "") : ""}
                  alt="QR Code"
                />
              </div>
              <span
                style={{
                  fontSize: "15px",
                  fontWeight: "500",
                  marginTop: "30px",
                  textAlign: "center",
                }}
              >
                {`vas.${virtualAccount}@idbi`}
              </span>
              <img
                style={{ marginTop: "30px", width: "60%" }}
                src={bottom}
                alt="Bottom Decoration"
              />
            </div>
          </Grid>

          {/* Right Section (User Account Details) */}
          <Grid item xs={12} sm={6} className="child-divone">
            <Useraccountdetail />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default Addmoney;
