import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import MemberlistUser from "./MemberlistUser";

const WalletUserTable = () => {
  const [wallets, setWallets] = useState([]);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const token = localStorage.getItem("adminAuthToken");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMember, setSelectedMember] = useState(null);
  const [showTable, setShowTable] = useState(true);
  const [userstatus, setUserstatus] = useState("ACTIVE");
  const [usercurrentstatus, setUsercurrentstatus] = useState("");

  console.log(selectedMember, "selectedMember");

  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const fetchWallets = async () => {
    const startDate = formatDate(fromDate);
    const endDate = formatDate(toDate);
    const res = await fetch(
      `${process.env.REACT_APP_API_URL}/admin/wallet?page=${
        page + 1
      }&perPage=${rowsPerPage}&start_date=${startDate}&end_date=${endDate}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();
    setWallets(data.data || []);
  };

  const fetchUsers = async (perPage = rowsPerPage, currentPage = page + 1) => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/user?page=${currentPage}&perPage=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      setUsers(data.data || []);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers(rowsPerPage, page + 1); // Fetch users when the component mounts or rowsPerPage/page changes
  }, [rowsPerPage, page]);

  useEffect(() => {
    fetchWallets();
  }, [fromDate, toDate, page, rowsPerPage]);

  const handleStatusChange = async (user_id, currentStatus) => {
    console.log(currentStatus, "currentStatus");
    // Determine the new status
    const newStatus = currentStatus === "ACTIVE" ? "IN_ACTIVE" : "ACTIVE";
    setUserstatus(newStatus);
    setUsercurrentstatus(newStatus);
    localStorage.setItem("status", userstatus);

    console.log(newStatus, "newStatus");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/user/status`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: user_id,
            status: newStatus,
          }),
        }
      );
      const result = await response.json();
      if (result.message === "User Status Updated Successfully") {
        // alert(`${result.message}, ${userstatus} for this user`);
        fetchWallets(); // Refresh data to reflect the change
        fetchUsers();
      } else {
        alert("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleSearchClick = () => {
    setPage(0);
    fetchWallets();
  };

  const findUserStatus = (userId) => {
    const user = users.find((user) => user.id === userId);
    return user ? user.status : "Not Found";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = +event.target.value; // Get the selected rowsPerPage value
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
    fetchUsers(newRowsPerPage, 1); // Fetch users with the updated perPage value
  };

  // Filter wallets based on search term
  const filteredWallets = wallets.filter(
    (wallet) =>
      wallet.user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      wallet.user?.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      wallet.user?.phone.includes(searchTerm) // New condition to filter by phone number
  );

  const handleViewClick = (user_id) => {
    const selectedWallet = wallets.find((wallet) => wallet.user_id === user_id);
    if (selectedWallet) {
      setSelectedMember({
        user_id: selectedWallet.user_id,
        balance: selectedWallet.balance,
        credit: selectedWallet.total_credit, // Assuming you want to send this
        debit: selectedWallet.total_debit, // Assuming you want to send this
        created_at: selectedWallet.created_at,
        memberId: selectedWallet.user?.userId || "N/A",
      });
    }
    setShowTable(false);
  };

  const handleBackClick = () => {
    setShowTable(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
      }}
    >
      {showTable ? (
        <>
          <span
            style={{
              paddingLeft: "20px",
              color: "gray",
              fontWeight: "bold",
              marginTop: "20px",
              fontSize: "25px",
            }}
          >
            Member List
          </span>
          <div
            style={{
              display: "flex",
              marginTop: "30px",
              padding: "10px",
              marginLeft: "150px",
              gap: 30,
            }}
          >
            <DatePicker
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              dateFormat="yyyy/MM/dd"
              isClearable
              placeholderText="From date"
              customInput={<TextField label="From date" variant="outlined" />}
            />
            <DatePicker
              selected={toDate}
              onChange={(date) => setToDate(date)}
              dateFormat="yyyy/MM/dd"
              isClearable
              placeholderText="To date"
              customInput={<TextField label="To date" variant="outlined" />}
            />
            <Button
              variant="contained"
              color="primary"
              style={{
                marginLeft: "10px",
                height: "56px",
                backgroundColor: "red",
                borderRadius: "10px",
              }}
              onClick={handleSearchClick}
            >
              Search
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "90px",
            }}
          >
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                width: "90%",
                height: "45px",
                borderRadius: "15px",
                borderColor: "lightGray",
                paddingLeft: "20px",
              }}
              placeholder="Search"
            />
          </div>
          <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "110px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
          <Paper sx={{ width: "100%", overflow: "hidden"}}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>MemberId</TableCell>
                    <TableCell>User Name</TableCell>

                    <TableCell>Phone Number</TableCell>
                    <TableCell>Debit</TableCell>
                    <TableCell>Credit</TableCell>
                    <TableCell>Balance</TableCell>
                    <TableCell>Detail</TableCell>
                    <TableCell>Account Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredWallets
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((wallet, index) => (
                      <TableRow hover key={wallet.user_id}>
                        <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                        <TableCell>{(wallet.user?.userId || "N/A").toUpperCase()}</TableCell>
                        <TableCell>{wallet.user?.name || "Unknown"}</TableCell>

                        <TableCell>{wallet.user?.phone || "N/A"}</TableCell>
                        <TableCell style={{ color: "red" }}>
                          {wallet.total_debit}
                        </TableCell>
                        <TableCell style={{ color: "green" }}>
                          {wallet.total_credit}
                        </TableCell>
                        <TableCell>{wallet.balance}</TableCell>
                        <TableCell>
                          <Button
                            style={{
                              borderColor: "red",
                              backgroundColor: "#fff",
                              color: "green",
                              borderWidth: "5px",
                            }}
                            onClick={() => handleViewClick(wallet.user_id)}
                            variant="contained"
                            color="primary"
                          >
                            View
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              width: "130px",
                              borderRadius: "20px",
                              height: "40px",
                            }}
                            // color={row.status === "ACTIVE" ? "success" : "error"}
                            onClick={() => {
                              // Debugging: Log user_id and status
                              // console.log("User ID:", row.user_id);
                              // console.log('Status:', row.status);
                              handleStatusChange(wallet.user_id, userstatus);
                            }}
                          >
                            {findUserStatus(wallet.user_id)}
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100, 500, 1000, 2000, 5000, 10000]}
              component="div"
              count={filteredWallets.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>

          </Grid>

        </>
      ) : (
        <MemberlistUser
          user_id={selectedMember}
          handleBackClick={handleBackClick}
        />
      )}
    </div>
  );
};

export default WalletUserTable;
