import React from "react";
import { Grid } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import "../css/Surcharge.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableVirtuoso } from "react-virtuoso";
import Button from "@mui/material/Button";

const columns = [
  {
    width: 200,
    label: "StartRange",
    dataKey: "startRange",
  },
  {
    width: 200,
    label: "EndRange",
    dataKey: "endRange",
  },
  {
    width: 200,
    label: "Surcharge",
    dataKey: "surcharge",
  },
  {
    width: 150,
    label: "LS Fort",
    dataKey: "lsFort",
  },
  {
    width: 200,
    label: "Edit",
    dataKey: "edit",
  },
];

// Defining the table rows with the required data
const rows = [
  {
    startRange: "1.00",
    endRange: "5000.00",
    surcharge: "10.00",
    lsFort: "1",
    edit: "",
  },
  {
    startRange: "5001.00",
    endRange: "50000.00",
    surcharge: "0.25",
    lsFort: "0",
    edit: "",
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table
      {...props}
      sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
    />
  ),
  TableHead: React.forwardRef((props, ref) => (
    <TableHead {...props} ref={ref} />
  )),
  TableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          style={{ width: column.width }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function rowContent(_index, row) {
  return (
    <React.Fragment>
      <TableCell>{row.startRange}</TableCell>
      <TableCell>{row.endRange}</TableCell>
      <TableCell>{row.surcharge}</TableCell>
      <TableCell>{row.lsFort}</TableCell>
      <TableCell>
        <Button variant="contained" color="primary" size="small">
          Edit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          style={{ marginLeft: "10px" }}
        >
          Delete
        </Button>
      </TableCell>
    </React.Fragment>
  );
}

function Subadminsurpluspayout() {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
          padding: "20px",
          paddingLeft: "70px",
          paddingRight: "70px",
        }}
      >
        <h4>Manage Payout Surcharge</h4>

        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} md={6}>
            <span>Package Name *</span>
          </Grid>
          <Grid item xs={12} md={6}>
            <input
              style={{
                width: "100%",
                height: "47px",
                borderRadius: "10px",
                borderColor: "lightgray",
              }}
              type="number"
            />
          </Grid>
        </Grid>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            marginTop: "20px",
            flexWrap: "wrap",
          }}
        >
          {/* Start Range */}
          <div style={{ flex: "1 1 20%", minWidth: "200px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Start Range{" "}
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              placeholder="Start Range"
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            />
          </div>

          {/* End Range */}
          <div style={{ flex: "1 1 20%", minWidth: "200px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              End Range{" "}
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              placeholder="End Range"
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            />
          </div>

          {/* Surcharge */}
          <div style={{ flex: "1 1 20%", minWidth: "200px" }}>
            <label
              style={{
                display: "block",
                marginBottom: "8px",
                fontWeight: "500",
              }}
            >
              Surcharge{" "}
              <span
                style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              placeholder="Surcharge"
              style={{
                width: "100%",
                padding: "10px",
                border: "1px solid #ccc",
                borderRadius: "10px",
              }}
            />
          </div>

          {/* Checkbox */}
          <div
            style={{
              flex: "1 1 10%",
              minWidth: "100px",
              display: "flex",
              alignItems: "center",
              marginTop: "40px",
              justifyContent: "center",
            }}
          >
            <span>Flat</span>
            <Checkbox {...label} defaultChecked />
          </div>

          {/* Submit Button */}
          <div
            style={{
              flex: "1 1 20%",
              minWidth: "200px",
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <button
              style={{
                padding: "10px 20px",
                backgroundColor: "orangered",
                color: "white",
                border: "none",
                borderRadius: "20px",
                cursor: "pointer",
                width: "70%",
              }}
            >
              Add / Update
            </button>
          </div>
        </div>

        <Paper style={{ height: 200, width: "100%" }}>
          <TableVirtuoso
            data={rows}
            components={VirtuosoTableComponents}
            fixedHeaderContent={fixedHeaderContent}
            itemContent={rowContent}
          />
        </Paper>

        <div style={{ display: "flex", marginLeft: "100px", gap: 20 }}>
          <button
            style={{
              backgroundColor: "orangered",
              color: "#fff",
              padding: "8px",
              fontSize: "15px",
              borderRadius: "15px",
              borderColor: "orangered",
            }}
          >
            Submit
          </button>
          <button
            style={{
              backgroundColor: "lightcoral",
              color: "#fff",
              padding: "8px",
              fontSize: "15px",
              borderRadius: "15px",
              borderColor: "lightcoral",
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
}

export default Subadminsurpluspayout;
