import React, { useState, useEffect } from "react";
import axios from "axios";
import Commontransition from "../../commoncomponents/Commontransition.jsx";
import useCommonBackground from "../../hook/Commonhook.jsx";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import DatePicker from "react-datepicker";
import TextField from "@mui/material/TextField";

const columns = [
    { id: "sno", label: "S.No", minWidth: 50 },
    { id: "name", label: "Name", minWidth: 150 },
    { id: "phone", label: "Phone", minWidth: 150 },
    { id: "email", label: "Email", minWidth: 200 },
    { id: "vc", label: "V/C", minWidth: 100 },
    { id: "date", label: "Date", minWidth: 150 },
];

function Registerusers() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [allRows, setAllRows] = useState([]); // Stores all fetched data
    const [rows, setRows] = useState([]); // Filtered data
    const [totalRows, setTotalRows] = useState(0);
    const [fromDate, setFromDate] = useState(null); // Start date
    const [toDate, setToDate] = useState(null); // End date
    const [searchTerm, setSearchTerm] = useState(""); // Search input

    const token = localStorage.getItem("adminAuthToken");

    useCommonBackground();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/admin/user`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const data = response.data.data;

            // Format data for the table
            const formattedData = data.map((item) => ({
                name: item.name,
                phone: item.phone,
                email: item.email,
                vc: item.virtual_account,
                date: new Date(item.updated_at), // Save Date object for filtering
                formattedDate: new Date(item.updated_at).toLocaleDateString("en-IN", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                }),
            }));

            setAllRows(formattedData); // Save original data
            setRows(formattedData); // Show all rows initially
            setTotalRows(formattedData.length);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleSearchClick = () => {
        if (!fromDate && !toDate) {
            setRows(allRows); // If no date range is selected, show all data
            return;
        }

        // Filter rows based on the selected date range
        const filteredRows = allRows.filter((row) => {
            const rowDate = new Date(row.date);
            if (fromDate && toDate) {
                return rowDate >= fromDate && rowDate <= toDate;
            } else if (fromDate) {
                return rowDate >= fromDate;
            } else if (toDate) {
                return rowDate <= toDate;
            }
            return true;
        });

        setRows(filteredRows);
        setTotalRows(filteredRows.length);
    };

    // Filter rows based on search term
    const handleSearchInput = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);

        const filteredRows = allRows.filter((row) =>
            row.name.toLowerCase().includes(value) ||
            row.phone.toLowerCase().includes(value) ||
            row.email.toLowerCase().includes(value) ||
            row.vc.toLowerCase().includes(value)
        );

        setRows(filteredRows);
        setTotalRows(filteredRows.length);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Commontransition
                text={"All Users Register Profiles"}
                transition={"R/P"}
                onClick={() => window.location.reload()}
            />

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                    padding: "10px",
                    gap: 30,
                }}
            >
                <DatePicker
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    dateFormat="yyyy/MM/dd"
                    isClearable
                    placeholderText="From date"
                    customInput={<TextField label="From date" variant="outlined" />}
                />
                <DatePicker
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    dateFormat="yyyy/MM/dd"
                    isClearable
                    placeholderText="To date"
                    customInput={<TextField label="To date" variant="outlined" />}
                />
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        marginLeft: "10px",
                        height: "56px",
                        backgroundColor: "red",
                        borderRadius: "10px",
                    }}
                    onClick={handleSearchClick}
                >
                    Search
                </Button>
            </div>

            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "90px",
                }}
            >
                <input
                    type="text"
                    value={searchTerm}
                    onChange={handleSearchInput}
                    style={{
                        width: "90%",
                        height: "45px",
                        borderRadius: "15px",
                        borderColor: "lightGray",
                        paddingLeft: "20px",
                    }}
                    placeholder="Search by Name, Email, Phone, or V/C"
                />
            </div>

            <Grid
                container
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "100px",
                    padding: "40px",
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
                }}
            >
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align="left"
                                            style={{ minWidth: column.minWidth }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            {columns.map((column) => {
                                                let value =
                                                    column.id === "sno"
                                                        ? page * rowsPerPage + index + 1
                                                        : column.id === "date"
                                                            ? row.formattedDate
                                                            : row[column.id];
                                                return (
                                                    <TableCell key={column.id} align="left">
                                                        {value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </div>
    );
}

export default Registerusers;
