import React, { useState } from "react";
import { Grid, Paper, Typography, TextField, Button } from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Checklisthook } from "../commoncomponents/commonhook/Checklisthook";

const ChildChecklist = ({ item, checkedItems, handleCheck }) => {
  return (
    <div style={{ marginLeft: "20px" }}>
      <input
        type="checkbox"
        id={item.id}
        checked={checkedItems.includes(item.id)}
        onChange={() => handleCheck(item.id)}
        style={{ marginBottom: "10px", transform: "scale(1.3)" }}
      />
      <label htmlFor={item.id} style={{ marginLeft: "10px" }}>
        {item.name}
      </label>
    </div>
  );
};

function Subadminregister() {
  const [checkedItems, setCheckedItems] = useState([]);
  const [openParents, setOpenParents] = useState({});
  const initialValues = {
    email: "",
    password: "",
    name: "",
    phone: "",
    mpin: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    phone: Yup.string()
      .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
      .required("Phone number is required"),
    mpin: Yup.string()
      .length(4, "MPIN must be 4 digits")
      .matches(/^\d+$/, "MPIN must be numeric")
      .required("MPIN is required"),
  });

  const handleCheck = (id) => {
    setCheckedItems((prevState) =>
      prevState.includes(id)
        ? prevState.filter((itemId) => itemId !== id)
        : [...prevState, id]
    );
  };

  const toggleParent = (parentId, parentChecked) => {
    setOpenParents((prevState) => ({
      ...prevState,
      [parentId]: parentChecked,
    }));
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formattedAccess = Checklisthook.reduce((acc, menu) => {
      const subMenus = menu.children
        .filter((child) => checkedItems.includes(child.id))
        .map((child) => child.name);
  
      if (checkedItems.includes(menu.id) || subMenus.length > 0) {
        acc[menu.name] = subMenus;
      }
  
      return acc;
    }, {});
  
    const userData = {
      name: values.name,
      email: values.email,
      password: values.password,
      phone: values.phone,
      mpin: values.mpin,
      permissions: formattedAccess,
    };
  
    try {
      const token = localStorage.getItem("adminAuthTokenstored"); // Replace with your actual token
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/subadmin/create-subadmin`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      if (response.status === 200 || response.status === 201) {
        alert("Subadmin registered successfully!");
        resetForm();
        setCheckedItems([]);
        setOpenParents({});
      }
    } catch (error) {
      console.error("Error registering subadmin:", error);
      alert("Failed to register subadmin. Please try again.");
    }
  };
  

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <h3>Sub Admin Register</h3>

      <Grid
        container
        spacing={3}
        style={{
          marginTop: "30px",
          padding: "30px",
          borderRadius: "10px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.5)",
        }}
      >
        <Grid item xs={12} sm={6}>
          <Paper
            style={{
              padding: "20px",
              backgroundColor: "#f4f4f4",
              borderRadius: "10px",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Typography variant="h6" gutterBottom>
              Register Subadmin
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form noValidate>
                  <Field
                    as={TextField}
                    name="name"
                    label="Name"
                    fullWidth
                    variant="outlined"
                    error={touched.name && !!errors.name}
                    style={{ marginBottom: "15px", maxWidth: "400px" }}
                    helperText={touched.name && errors.name}
                  />
                  <Field
                    as={TextField}
                    name="email"
                    label="Email Id"
                    fullWidth
                    variant="outlined"
                    error={touched.email && !!errors.email}
                    style={{ marginBottom: "15px", maxWidth: "400px" }}
                    helperText={touched.email && errors.email}
                  />
                  <Field
                    as={TextField}
                    name="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    error={touched.password && !!errors.password}
                    style={{ marginBottom: "15px", maxWidth: "400px" }}
                    helperText={touched.password && errors.password}
                  />
                  <Field
                    as={TextField}
                    name="phone"
                    label="Phone Number"
                    type="tel"
                    fullWidth
                    variant="outlined"
                    error={touched.phone && !!errors.phone}
                    style={{ marginBottom: "15px", maxWidth: "400px" }}
                    helperText={touched.phone && errors.phone}
                  />
                  <Field
                    as={TextField}
                    name="mpin"
                    label="Mpin"
                    fullWidth
                    variant="outlined"
                    error={touched.mpin && !!errors.mpin}
                    style={{ marginBottom: "15px", maxWidth: "400px" }}
                    helperText={touched.mpin && errors.mpin}
                  />
                  <div style={{display:"flex", flexDirection:"column"}}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{
                      marginTop: "15px",
                      maxWidth: "400px",
                      width: "20%",
                    }}
                  >

                    Submit
                  </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Paper
            style={{
              padding: "20px",
              backgroundColor: "#f4f4f4",
              borderRadius: "10px",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div>
              <h2>Access given to subadmin</h2>
              {Checklisthook.map((item) => (
                <div key={item.id}>
                  <input
                    style={{ marginBottom: "10px", transform: "scale(1.3)" }}
                    type="checkbox"
                    id={item.id}
                    checked={checkedItems.includes(item.id)}
                    onChange={(e) => {
                      handleCheck(item.id);
                      toggleParent(item.id, e.target.checked);
                    }}
                  />
                  <label htmlFor={item.id} style={{ marginLeft: "10px" }}>
                    {item.name}
                  </label>

                  {item.children.length > 0 && openParents[item.id] && (
                    <div>
                      {item.children.map((child) => (
                        <ChildChecklist
                          key={child.id}
                          item={child}
                          checkedItems={checkedItems}
                          handleCheck={handleCheck}
                        />
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Subadminregister;
