import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Back from "../assets/back.png";
import { getSubadminDetails } from '../api/Api';

// Function to format the permissions
const formatPermissions = (permissions) => {
    if (!permissions || typeof permissions !== 'object') {
        return 'No Permissions';
    }

    return Object.entries(permissions)
        .map(([key, values]) => {
            const formattedValues = Array.isArray(values) ? values.join(', ') : 'Invalid Permissions';
            return `${key}: ${formattedValues}`;
        })
        .join('; ');
};

// Function to format date
const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const columns = [
    { id: 'sno', label: 'S.No', minWidth: 50 },
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'phone', label: 'Phone', minWidth: 150 },
    { id: 'email', label: 'Email', minWidth: 200 },
    { id: 'permissions', label: 'Permissions', minWidth: 250 },
    { id: 'date', label: 'Date', minWidth: 170 },
];

function Subadminspecificmembers({ handleBackClick, userId, }) {
    const [rows, setRows] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        // Fetch user details using the passed userId
        getSubadminDetails(userId)
            .then((response) => {
                // Check if response data is valid and an object
                if (response.data && typeof response.data === 'object') {
                    const item = response.data; // Directly access the object
                
                    // Convert object into row format
                    const data = [{
                        sno: 1, // Since it's a single object, we set sno as 1
                        id: item.id,
                        name: item.name || 'N/A',
                        phone: item.phone || 'N/A',
                        email: item.email || 'N/A',
                        permissions: formatPermissions(item.permissions),
                        date: formatDate(item.updatedAt),
                    }];

                    setRows(data);
                    setFilteredRows(data);
                } else {
                    console.error("Invalid data structure received from API:", response.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching user details:', error);
            });
    }, [userId]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div>
            <img
                onClick={() => handleBackClick()}
                style={{
                    width: "60px",
                    height: "60px",
                    marginLeft: "10px",
                    marginTop: "10px",
                }}
                alt=""
                src={Back}
            />
            <h4
                style={{
                    fontWeight: "500",
                    marginTop: "30px",
                    paddingLeft: "20px",
                    cursor: "pointer",
                }}
            >
                Specific Subadmin
            </h4>

            <Paper style={{ width: '100%', overflow: 'hidden', marginTop: "90px" }}>
                <TableContainer style={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.id === 'view' ? 'center' : 'left'}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.id === 'view' ? 'center' : 'left'}>
                                                    {value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

export default Subadminspecificmembers;
