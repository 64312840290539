import React from 'react'

function Commonbutton({ styles = {}, type, text, onClick }) {

    const defaultStyles = {
        backgroundColor: "rgb(53, 132, 173)",
        color: "#fff",
        padding: "10px 20px",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        fontSize: "16px",
        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.1)",
        transition: "background-color 0.3s ease",
    };


    return (
        <>
            <button
                onClick={onClick}
                style={{ ...defaultStyles, ...styles }} // Merge default styles with custom styles
                type={type ? type : "submit"}
            >
                {text}
            </button>
        </>
    )
}

export default Commonbutton