import React, { useState, useEffect } from 'react';
import Commontransition from "../../commoncomponents/Commontransition.jsx";
import useCommonBackground from "../../hook/Commonhook.jsx";
import DatePicker from "react-datepicker";
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, TextField } from "@mui/material";

const columns = [
  { id: 'sno', label: 'S.No', minWidth: 50 },
  { id: 'memberId', label: 'MemberId', minWidth: 100 },
  { id: 'amount', label: 'Amount', minWidth: 170 },
  { id: 'upiid', label: 'UPI Id', minWidth: 170 },
  { id: 'bankrrnutr', label: 'BANKRRN/UTR', minWidth: 170 },
  { id: 'date', label: 'Date', minWidth: 170 },
];

function Newupireport() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10000);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useCommonBackground();

  const fetchData = async (startDate, endDate, page, rowsPerPage) => {
    try {
      const token = localStorage.getItem("adminAuthToken");
      let url = `https://zevopay.online/api/v1/admin/transaction?page=${page + 1}&perPage=${rowsPerPage}`;

      // Add date range parameters if provided
      if (startDate && endDate) {
        url += `&start_date=${startDate}&end_date=${endDate}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      // Filter rows where mode is "UPI"
      const filteredData = data.data.filter(item => item.mode === "UPI");

      // Total filtered data for UPI
      const totalFilteredPages = filteredData.length;

      // Update rows with paginated UPI mode data
      const serverRows = filteredData
        .slice(page * rowsPerPage, (page + 1) * rowsPerPage) // Paginate the filtered data
        .map((item, index) => ({
          sno: page * rowsPerPage + index + 1,
          memberId: item.user.userId,
          amount: item.tranAmt,
          upiid: `vas.${item.van.toLowerCase()}@idbi`,
          bankrrnutr: item.utr,
          date: new Date(item.created_at).toLocaleString(),
        }));

      setRows(serverRows);
      setTotalPages(totalFilteredPages); // Update the totalPages for filtered UPI data
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData(startDate, endDate, page, rowsPerPage);
  }, [startDate, endDate, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    setPage(0); // Reset to the first page
    fetchData(startDate, endDate, 0, rowsPerPage);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Commontransition text={"New Upi Report"} transition={"V/C"} onClick={() => window.location.reload()} />
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "10px",
            height: "56px",
            backgroundColor: "red",
            borderRadius: "10px",
          }}
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "200px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, 1000, 5000, 10000]}
            component="div"
            count={totalPages}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
}

export default Newupireport;
