// src/api/api.js
import axios from "axios";

// Base URL configuration
const token = localStorage.getItem("adminAuthToken");
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
    // "Content-Type": "application/json",
  },
});

// Example API functions
export const getSubadminData = () => {
  return apiClient.get(`/admin/subadmins`);
};

export const getSubadminDetails = (id) => {
  return apiClient.get(`/subadmin/${id}`); // Replace with your actual endpoint for specific user details
};

