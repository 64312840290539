import React, { useEffect, useState } from "react";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import CommonGroupaddicon from "../../commoncomponents/CommonGroupaddicon";
import axios from "axios"; // Import axios for API calls

const Subadminhome = () => {
  const [dashboard, setDashboardData] = useState({
    totalMembers: 0,
    totalBalance: 0,
  }); // Initialize state with default values
  const token = localStorage.getItem("subadmin");

  useEffect(() => {
    const fetchDashboardSummary = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/subadmin/dashboard/summary`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          }
        );
        setDashboardData(response.data); // Extract and set the data from the API response
      } catch (error) {
        console.error("Error fetching dashboard summary:", error);
      }
    };

    fetchDashboardSummary();
  }, []);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: "darkred",
      ...theme.applyStyles("dark", {
        backgroundColor: "#308fe8",
      }),
    },
  }));

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap", // Enable wrapping for items
          gap: "10px",
          backgroundColor: "#fff",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
          padding: 20,
        }}
      >
        {/* Card 1 */}
        <CommonGroupaddicon
          text={`${dashboard.totalMembers}`}
        />

        {/* Card 2 */}
        <CommonGroupaddicon
          text={`₹${dashboard.totalBalance}`}
          progressColor="#90EE90"
          styles={{ color: "#90EE90", fontWeight: "bold" }}
          stylesIcon={{
            width: "100px",
            height: "100px",
            backgroundColor: "#90EE90",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          footertext={"E Wallet Balance"}
        />

        {/* Card 3 */}
        <CommonGroupaddicon
          progressColor="#FFA07A"
          styles={{ color: "#FFA07A", fontWeight: "bold" }}
          stylesIcon={{
            width: "100px",
            height: "100px",
            backgroundColor: "#FFA07A",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          footertext={"AEPS Wallet"}
        />

        {/* Card 4 */}
        <CommonGroupaddicon
          progressColor="#87CEFA"
          styles={{ color: "#87CEFA", fontWeight: "bold", marginTop: "40px" }}
          stylesIcon={{
            width: "100px",
            height: "100px",
            backgroundColor: "#87CEFA",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          footertext={"Pending Fundrequest"}
        />
      </div>
    </div>
  );
};

export default Subadminhome;
