import React, { useState, useEffect } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Back from "../assets/back.png";
import { format } from "date-fns";

const columns = [
  { id: "sno", label: "S.No", minWidth: 50 },
  { id: "memberId", label: "Member ID", minWidth: 100 },
  { id: "date", label: "Date", minWidth: 200 },
  { id: "narration", label: "Narration", minWidth: 100 },
  { id: "credit", label: "Credit", minWidth: 100 },
  { id: "debit", label: "Debit", minWidth: 100 },
  { id: "balance", label: "Balance", minWidth: 100 },
];

function createData(sno, memberId, date, narration, credit, debit, balance) {
  return { sno, memberId, date, narration, credit, debit, balance };
}

const Subadminspecificreport = ({ user_id, handleBackClick }) => {
  console.log(user_id, "userdataid")
  const userId = user_id.user_id;

  console.log(user_id, "user_iddata")

  const { balance: initialBalance, memberId } = user_id;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [data, setData] = useState([]);

  const token = localStorage.getItem("subadmin");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const fetchInitialrow = () => {
    const registrationDate = new Date(user_id.created_at);
    // Check if the registration date falls within the selected date range
    if (registrationDate >= fromDate && registrationDate <= toDate) {
      return createData(
        0, // S.No (to be updated later)
        user_id.memberId, // Member ID
        user_id.created_at, // Date
        "Registration", // Narration
        0, // Credit
        0, // Debit
        0 // Balance
      );
    }
    return null; // Return null if the date is outside the range
  };


  const fetchData = async () => {
    try {
      const allData = [];
      let currentPage = 1;
      let lastPage = 1;

      do {
        const formattedStartDate = fromDate ? format(fromDate, "yyyy-MM-dd") : null;
        const formattedEndDate = toDate ? format(toDate, "yyyy-MM-dd") : null;
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/subadmin/transaction/${userId}`,
          {
            params: {
              page: currentPage,
              perPage: 10,
              start_date: formattedStartDate,
              end_date: formattedEndDate,
            },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const { data: apiData, meta } = response.data;

        allData.push(...apiData);
        currentPage = meta.next;
        lastPage = meta.lastPage;
      } while (currentPage);

      const transformedData = allData.map((item, index) => {
        const isCredit = ["CASH CREDIT", "UPI", "IMPS", "NEFT", "RTGS"].includes(item.mode);
        const isDebit = item.mode === "CASH DEBIT";

        const narration =
        item.mode === "UPI"
          ? `Add Amt By UPI / ${item.utr}`
          : item.mode === "IMPS"
          ? `Add Amt By virtual A/C / IMPS / ${item.Sender_receiver_info.split('/')[1]}` // Extract the number after "IMPS/"
          : item.mode === "NEFT"
          ? `Add Amt By virtual A/C / NEFT / ${item.utr}`
          : item.mode === "RTGS"
          ? `Add Amt By virtual A/C / RTGS / ${item.utr}`
          : item.Sender_receiver_info;

        return createData(
          index + 1,
          memberId,
          item.updated_at,
          narration,
          isCredit ? item.tranAmt : 0,
          isDebit ? item.tranAmt : 0,
          0 // Balance to be calculated
        );
      });

      const reversedData = transformedData.reverse();

      // Fetch the initial balance row conditionally
      const balanceRow = fetchInitialrow();

      // Combine data
      const combinedData = balanceRow ? [balanceRow, ...reversedData] : reversedData;

      let runningBalance = 0;
      const updatedData = combinedData.map((row, index) => {
        if (index === 0) {
          row.balance = row.credit;
          runningBalance = parseFloat(row.balance);
        } else {
          runningBalance += parseFloat(row.credit) - parseFloat(row.debit);
          row.balance = runningBalance.toFixed(2);
        }
        return row;
      });

      const dataWithCorrectSerials = updatedData.map((row, index) => ({
        ...row,
        sno: index + 1,
      }));

      setData(dataWithCorrectSerials);
      setFilteredRows(dataWithCorrectSerials);
      console.log(filteredRows, "aagyafilter");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);


  useEffect(() => {
    const filtered = data.filter((row) => {
      if (!fromDate || !toDate) {
        return true; // Include all rows if no date range is selected
      }
      const rowDate = new Date(row.date);
      return rowDate >= fromDate && rowDate <= toDate;
    });

    const reversedAndRenumberedRows = filtered.reverse().map((row, index) => ({
      ...row,
      sno: index + 1, // Renumbering S.No from 1, 2, 3, etc.
    }));

    setFilteredRows(reversedAndRenumberedRows);
  }, [data, fromDate, toDate]);

  // Add a useEffect to log changes to filteredRows
  useEffect(() => {
    console.log(filteredRows, "filteredRows updated");
  }, [filteredRows]);


  console.log(filteredRows, "nhi aaya")

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      <img
        onClick={() => handleBackClick()}
        style={{
          width: "60px",
          height: "60px",
          marginLeft: "10px",
          marginTop: "10px",
        }}
        alt=""
        src={Back}
      />
      <h4
        style={{
          fontWeight: "500",
          marginTop: "30px",
          paddingLeft: "20px",
          cursor: "pointer",
        }}
      >

        Report specific user
      </h4>
      <div
        style={{
          display: "flex",
          marginTop: "30px",
          alignItems: "center",
          padding: "10px",
          justifyContent: "center",
          gap: 30,
        }}
      >
        <DatePicker
          selected={fromDate}
          onChange={(date) => setFromDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => setToDate(date)}
          dateFormat="yyyy/MM/dd"
          isClearable
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          variant="contained"
          color="primary"
          style={{
            marginLeft: "10px",
            height: "56px",
            backgroundColor: "red",
            borderRadius: "10px",
          }}
          onClick={fetchData}
        >
          Search
        </Button>
      </div>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "230px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.sno}>
                      {columns.map((column) => {
                        const value =
                          column.id === "date"
                            ? new Date(row.date).toLocaleString()
                            : row[column.id];
                        return (
                          <TableCell
                            style={{
                              color:
                                column.id === "credit"
                                  ? "green"
                                  : column.id === "debit"
                                    ? "red"
                                    : "black",
                            }}
                            key={column.id}
                            align={column.align}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
};

export default Subadminspecificreport;
