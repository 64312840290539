import React from 'react'

function Commonfield({ type, name, onchange, onblur, values, placeholder, id }) {
    return (
        <>
            <input
                type={type}
                name={name}
                onChange={onchange}
                onBlur={onblur}
                value={values}
                placeholder={placeholder}
                id={id}
            />
        </>
    )
}

export default Commonfield