import React, { useEffect, useState } from "react";
import Commontransition from "../commoncomponents/Commontransition";
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, Button, TextField, TableHead, TablePagination, TableRow,  } from "@mui/material";
import DatePicker from "react-datepicker";
import useCommonBackground from "../hook/Commonhook.jsx";
const columns = [
  { id: "sno", label: "S.NO", minWidth: 50, },
  { id: "MemberId", label: "MemberId", minWidth: 150 },
  { id: "tranAmt", label: "Amount", minWidth: 150 },
  { id: "UPId", label: "UPId", minWidth: 200 }, // New UPId column
  { id: "utr", label: "BANKRRN/UTR", minWidth: 150 },
  { id: "trandate", label: "Date", minWidth: 200 },
];

function Newpayoutreport() {

  useCommonBackground();

  const [state, setState] = useState({
    page: 0,
    rowsPerPage: 10,
    rows: [],
    memberdata: [],
  });

  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
  });

  const MemberId = localStorage.getItem("UserId") // Retrieve MemberId from localStorage
  const token = localStorage.getItem("authToken");

  const datafetch = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL + "/user"}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responsedata = await response.json();

      setState((prevstate) => ({
        ...prevstate,
        memberdata: responsedata
      }));
      // setMemberdata(responsedata);
      console.log(state.memberdata, "responsedata");
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    datafetch();
  }, []);

  const virtualAccount = state.memberdata?.virtual_account
    ? state.memberdata.virtual_account.toLowerCase()
    : "";

  const userUpiID = `vas.${virtualAccount}@idbi`; // Generate userUpiID
  localStorage.setItem('upiid', userUpiID);
  const handleSearch = () => {
    fetchData(filters.fromDate, filters.toDate);
  };

  const fetchData = async (fromDate, toDate) => {
    const url = `${process.env.REACT_APP_API_URL}/wallet/transactions`;
    try {
      const params = new URLSearchParams();
      // Add the date range parameters to the query if dates are selected
      if (fromDate) params.append("start_date", fromDate.toISOString());
      if (toDate) params.append("end_date", toDate.toISOString());

      const response = await fetch(`${url}?${params.toString()}`, {
        method: "GET",
        params: {
          page: 1,
          perPage: 10,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const result = await response.json();
      if (result.data) {
        const filteredData = result.data
          .filter((item) => item.mode === "UPI")
          .map((item) => ({
            ...item,
            MemberId, // Add MemberId to each row
            UPId: localStorage.getItem("upiid"), // Add UPId to each row
          }));

        setState((prevstate) => ({
          ...prevstate,
          rows: filteredData,
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Initial fetch when component mounts

  console.log(state.rows, "rows")

  const handleChangePage = (event, newPage) => {
    setState((prevstate) => ({
      ...prevstate,
      page: newPage
    }))
  };

  const handleChangeRowsPerPage = (event) => {
    setState((prevstate) => ({
      ...prevstate,
      rowsPerPage: (+event.target.value),
      page: 0
    }))

  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Commontransition
        text={"UPI Report"}
        transition={"UPI"}
        onClick={() => window.location.reload()}
      />
      <div style={{ display: "flex", marginTop: "10px", alignItems: "center", padding: "10px", justifyContent: "center", gap: 30 }}>
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          selected={filters.fromDate}
          onChange={(date) => setFilters((prev) => ({ ...prev, fromDate: date }))}
          placeholderText="From date"
          customInput={<TextField label="From date" variant="outlined" />}
        />
        <DatePicker
          className="date-picker-container"
          dateFormat="yyyy/MM/dd"
          isClearable
          selected={filters.toDate}
          onChange={(date) => setFilters((prev) => ({ ...prev, toDate: date }))}
          placeholderText="To date"
          customInput={<TextField label="To date" variant="outlined" />}
        />
        <Button
          onClick={handleSearch}
          variant="contained"
          color="primary"
          style={{ marginLeft: "10px", height: "56px" }}
        >
          Search
        </Button>
      </div>

      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "200px",
          padding: "40px",
          borderRadius: "5px",
          backgroundColor: "#fff",
          boxShadow: "2px 2px 5px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Paper style={{ width: "100%", overflow: "hidden", }}>
          <TableContainer style={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.rows
                  .slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        {columns.map((column) => {
                          const value =
                            column.id === "sno"
                              ? state.page * state.rowsPerPage + index + 1
                              : row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={state.rows.length}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </div>
  );
}

export default Newpayoutreport;
