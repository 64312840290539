import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import Homepage from "../pages/Homepage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "../pages/Login";
import AdminLogin from "../admin/Adminlogin";
import AdminDashboard from "../admin/admindashboard/AdminDashboard";
import Services from "../screen/Services";
import Career from "../screen/Career";
import Forgotpassword from "../pages/Forgotpassword";
import Contact from "../screen/Contact";
import Company from "../screen/Company";
import Qrcodepage from "../pages/Qrcodepage";
import Resetpassword from "../screen/Resetpassword";
import Subadminlogin from "../subadmin/login/Subadminlogin";
import Subadmindashboard from "../subadmin/subadmindashboard/Subadmindashboard";
import Forgotmpin from "../pages/Forgotmpin";
import Resetmpin from "../screen/Resetmpin";
import SubadminForgotpassword from "../subadmin/pages/SubadminForgotpassword";
import SubadminForgotmpin from "../subadmin/pages/SubadminForgotmpin";
import SubadminResetpassword from "../subadmin/pages/SubadminResetpassword";
import SubadminResetmpin from "../subadmin/pages/SubadminResetmpin";

const Navigators = () => {
  const [loggedIn, setLoggedIn] = useState(!!localStorage.getItem("authToken"));
  const [adminloggedin, setAdminlogedin] = useState(
    !!localStorage.getItem("adminAuthToken")
  );
  const [adminTokenStored, setAdminTokenStored] = useState(
    !!localStorage.getItem("adminAuthTokenstored")
  );
  const [subadminloggedin, setSubadminLoggedin] = useState(
    !!localStorage.getItem("subadminAuthToken")
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const adminAuthTokenstored = localStorage.getItem("adminAuthTokenstored");
    const subadminAuthToken = localStorage.getItem("subadmin");

    setLoggedIn(!!authToken);
    setAdminTokenStored(!!adminAuthTokenstored);
    setSubadminLoggedin(!!subadminAuthToken);
    setLoading(false); // Set loading to false after checking authToken
  }, []);

  // User private routing
  const PrivateRoute = ({ element }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return loggedIn ? element : <Navigate to="/Login" />;
  };

  // Admin private routing
  const Adminprivateroute = ({ element }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return adminloggedin ? element : <Navigate to="/AdminLogin" />;
  };

  const AdminTokenStoredRoute = ({ element }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return adminTokenStored ? element : <Navigate to="/AdminLogin" />;
  };

  // Subadmin private routing
  const SubadminPrivateRoute = ({ element }) => {
    if (loading) return null; // Show nothing or a loading spinner while checking auth
    return subadminloggedin ? element : <Navigate to="/Subadmin-login" />; // Redirect to login if not logged in
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Career" element={<Career />} />
        <Route path="/reset-password" element={<Resetpassword />} />
        <Route path="/reset-mpin" element={<Resetmpin />} />
        <Route path="/Forgotmpin" element={<Forgotmpin />} />
        <Route
          path="/Subadmin-login"
          element={<Subadminlogin setSubadminLoggedin={setSubadminLoggedin} />}
        />
        <Route
          path="/Subadmin-dashboard"
          element={<SubadminPrivateRoute element={<Subadmindashboard />} />}
        />
        <Route path="/Forgotpassword" element={<Forgotpassword />} />
        <Route
          path="/Qrcodepage"
          element={<AdminTokenStoredRoute element={<Qrcodepage />} />}
        />
        <Route path="/Company" element={<Company />} />
        <Route path="/Login" element={<Login setLoggedIn={setLoggedIn} />} />
        <Route
          path="/AdminLogin"
          element={<AdminLogin setAdminlogedin={setAdminlogedin} />}
        />
        <Route
          path="/AdminDashboard"
          element={<Adminprivateroute element={<AdminDashboard />} />}
        />
        <Route
          path="/Dashboard"
          element={<PrivateRoute element={<Dashboard />} />}
        />
        <Route path="*" element={<Homepage />} />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default Navigators;
