import { useEffect } from "react";

const useCommonBackgrouncolor = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#4F84C4";
    return () => {
      document.body.style.backgroundColor = ""; // Revert to default background color on unmount
    };
  }, []); // Dependency array ensures it runs only once
};

export default useCommonBackgrouncolor;
